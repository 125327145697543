import MainLayout from "../../components/Layouts";
import CompanyTopUpRequst from "../../components/TopUpRequest/index";
import React from "react";

const TopUpPage = () => {
  return (
    <MainLayout>
      <CompanyTopUpRequst />
    </MainLayout>
  );
};

export default TopUpPage;
