import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFound from "../components/common/404";
import Login from "../components/Login/index";
import CompanySignup from "../components/Login/signup";
import React from "react";
import DashboardPage from "../pages";
import GroupLisrPage from "../pages/group/index";
import EmployeeGroupLisrPage from "../pages/employeeGroup/index";
import DepartmentPage from "../pages/department/index";
import CompanyDepositPage from "../pages/company-deposit/index";
import CompanyDepositCreatePage from "../pages/company-deposit/create";
import DesignationPage from "../pages/designation/index";
import UserLisrPage from "../pages/user/index";
import UserCreatePage from "../pages/user/create";
import EmployeeLisrPage from "../pages/employee/index";
import EmployeeTopUpPage from "../pages/employee/employeeTopup";
import EmployeeCreatePage from "../pages/employee/create";
import TopUpLisrPage from "../pages/topup-history/index";
import RequestTopUpLisrPage from "../pages/topup-history/company-topup-request-list";
import RequestTopUpDetailsPage from "../pages/topup-history/company-request-topup-details";
import TopUpPage from "../pages/topup/index";
import TopUpRequstPage from "../pages/topup-request/index";
import TopUpDetailsPage from "../pages/topup-history/details";
import CashPaymentListPage from "../pages/cash-payment/index";
import CashPaymentRequstPage from "../pages/cash-payment/paymentRequest";
import ChangePasswordPage from "../pages/change-password";

const MainRoutes = (): any => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  return (
    <BrowserRouter>
      <Routes>
        {!token ? (
          <>
            <Route path="*" element={<Login />} />
            <Route path={`${process.env.PUBLIC_URL}/`} element={<Login />} />
            <Route
              path={`${process.env.PUBLIC_URL}/signup`}
              element={<CompanySignup />}
            />
          </>
        ) : (
          <>
            <Route path="*" element={<NotFound />} />
            <Route
              path={`${process.env.PUBLIC_URL}/`}
              element={<DashboardPage />}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/group-list`}
              element={<GroupLisrPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/employee-group-list`}
              element={<EmployeeGroupLisrPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/department-list`}
              element={<DepartmentPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/deposit-order`}
              element={<CompanyDepositPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/deposit-order-create`}
              element={<CompanyDepositCreatePage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/designation-list`}
              element={<DesignationPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/user-list`}
              element={<UserLisrPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/user-create`}
              element={<UserCreatePage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/user/:userId/edit`}
              element={<UserCreatePage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/employee-list`}
              element={<EmployeeLisrPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/employee-create`}
              element={<EmployeeCreatePage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/employee/:employeeId/edit`}
              element={<EmployeeCreatePage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/topup`}
              element={<TopUpPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/topup-requst`}
              element={<TopUpRequstPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/employee-topup`}
              element={<EmployeeTopUpPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/request-topup-list`}
              element={<RequestTopUpLisrPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/request-topup-list/:topupId/details`}
              element={<RequestTopUpDetailsPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/topup-list`}
              element={<TopUpLisrPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/topup-list/:topupId/details`}
              element={<TopUpDetailsPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/payment-request-list`}
              element={<CashPaymentListPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/cash-payment`}
              element={<CashPaymentRequstPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/change-password`}
              element={<ChangePasswordPage />}
            />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};
export default MainRoutes;
