import MainLayout from "../../components/Layouts";
import CompanyUserList from "../../components/Users/index";
import React from "react";

const UserListPage = () => {
  return (
    <MainLayout>
      <CompanyUserList />
    </MainLayout>
  );
};

export default UserListPage;
