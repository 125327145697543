import { Button, Divider, Form, Input, Select, Switch, Tooltip } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import React from "react";
import axios from "axios";
import { debounce } from "lodash";
import { useNavigate, useParams } from "react-router";
import { ImageUploader, SubmitResetBtn } from "../common";
import { QuestionCircleOutlined } from "@ant-design/icons";

const AddUser = ({ visibleData, onCloseMethod }: any) => {
  const fetchRef = useRef(0);
  const route = useParams();
  const navitage = useNavigate();
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  console.log("initialState <======>", initialState);
  const companyId = initialState?.companyId;
  console.log("companyId=====", companyId);
  const [countryId, setCountryId] = useState<any>(0);
  const [stateId, setStateId] = useState<any>(0);
  const [cityId, setCityId] = useState<any>(0);
  const [profileUrl, setProfileUrl] = useState<string>();
  console.log("profileUrl", profileUrl);
  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [stateOptions, setStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchUserDetails = useCallback((companyUserId: any) => {
    if (companyUserId) {
      try {
        setUserData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/company-user/details?companyUserId=${companyUserId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setUserData({
                loading: false,
                data: data?.data?.companyUser,
              });
              setCountryId(
                data?.data?.countryId
              );
              setStateId(
                data?.data?.stateId
              );
              setCityId(
                data?.data?.city
              );
            } else {
              setUserData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setUserData({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setUserData({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);
  useEffect(() => {
    fetchUserDetails((route as any)?.userId);
  }, [fetchUserDetails]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      address: data.address,
      name: data.name,
      mobileNumber: `+88${data.mobileNumber?.replace("+88", "")}`,
      email: data.email,
      street: data.street,
      password: data.password,
      hasApprovePermission: data.hasApprovePermission,
      isActive: data.isActive,
      image: profileUrl,
      countryId: countryId,
      stateId: stateId,
      city: cityId,
      type: initialState?.shopInfo?.type,
    };

    if (userData?.data) {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/company-user/update-user`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: userData?.data?.companyUserId,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("User Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
            navitage(-1);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_RIDER_API}/company-user/add-user`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("User Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
            navitage(-1);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const getCountryOptions = useCallback(async (key?: string) => {
    setCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
  }, []);

  const getStateOptions = useCallback(
    async (key?: string) => {
      setStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${countryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [countryId]
  );

  const getCityOptions = useCallback(
    async (key?: string) => {
      setCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${stateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [stateId]
  );

  useEffect(() => {
    if (visibleData) {
      setUserData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    getCountryOptions();
    getStateOptions();
    getCityOptions();
  }, [getCountryOptions, getStateOptions, getCityOptions]);

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "country") getCountryOptions(value);
        if (field === "state") getStateOptions(value);
        if (field === "city") getCityOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCountryOptions, getStateOptions, getCityOptions]);

  useEffect(() => {
    if (userData?.data) {
      form?.resetFields();
      setProfileUrl(userData?.data?.logo);
      setCountryId(userData?.data?.countryId);
      setStateId(userData?.data?.stateId);
      setCityId(userData?.data?.city);
    }
  }, [userData]);

  return (
    <div className="mt-6">
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...userData?.data,
        }}
      >
        <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
          <div className="grid grid-cols-2 gap-x-6">
            <div>
              <Form.Item
                hasFeedback
                label="User Name"
                rules={[
                  {
                    required: true,
                    message: "Name is Required!",
                  },
                ]}
                name="name"
                // initialValue={userData?.data?.name}
              >
                <Input id="name" type="text" placeholder="Enter User Name" />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="User Email"
                rules={[
                  {
                    required: true,
                    message: "Email is Required!",
                  },
                ]}
                name="email"
                // initialValue={userData?.data?.name}
              >
                <Input id="email" type="email" placeholder="Enter User Email" />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="Mobile Number"
                rules={[
                  {
                    required: !userData?.data?.mobileNumber,
                    message: "Mobile Number is required!",
                  },
                  {
                    validator: async (_, names) => {
                      if (
                        !userData?.data?.mobileNumber &&
                        !names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)
                      ) {
                        return Promise.reject(
                          new Error("Enter valid mobile number")
                        );
                      }
                    },
                  },
                ]}
                name="mobileNumber"
                initialValue={userData?.data?.mobileNumber || "01"}
              >
                <Input
                  disabled={userData?.data}
                  id="mobileNumber"
                  type="contact"
                  addonBefore={"+88"}
                  placeholder="Enter Mobile Number"
                  defaultValue={userData?.data?.mobileNumber}
                  value={userData?.data?.mobileNumber}
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label={`Country/Region`}
                rules={[
                  {
                    required: false,
                    message: "Region is required!",
                  },
                ]}
                name="countryId"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Country"
                  optionFilterProp="children"
                  onChange={(e) => setCountryId(e)}
                  onSearch={(val) => {
                    handleSearch?.(val, "country");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={countryOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label={`State/Province`}
                rules={[
                  {
                    required: false,
                    message: "State is required!",
                  },
                ]}
                name="stateId"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select State"
                  optionFilterProp="children"
                  onChange={(val) => setStateId(val)}
                  onSearch={(val) => {
                    handleSearch?.(val, "state");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={stateOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label={`City`}
                rules={[
                  {
                    required: false,
                    message: "City is required!",
                  },
                ]}
                name="cityId"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select City"
                  optionFilterProp="children"
                  onChange={(val) => setCityId(val)}
                  onSearch={(val) => {
                    handleSearch?.(val, "city");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={cityOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Address"
                rules={[
                  {
                    required: false,
                    message: "Address is Required!",
                  },
                ]}
                name="address"
              >
                <Input id="address" type="text" placeholder="Enter Address" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Street"
                rules={[
                  {
                    required: false,
                    message: "street is Required!",
                  },
                ]}
                name="street"
              >
                <Input id="street" type="text" placeholder="Enter street" />
              </Form.Item>
            </div>
            <div>
              <Form.Item hasFeedback label="password" name="password">
                <Input
                  disabled={userData?.data}
                  id="password"
                  type="password"
                  placeholder="Enter password"
                  min={6}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Has Approve Permission?"
                name="hasApprovePermission"
                className="mr-5"
              >
                <Switch
                  checkedChildren={`true`}
                  unCheckedChildren={`false`}
                  //   defaultChecked={
                  //     (singleProductInfo as any)?.data?.hasApprovePermission
                  //   }
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="is Active?"
                name="isActive"
                className="mr-5"
              >
                <Switch
                  checkedChildren={`true`}
                  unCheckedChildren={`false`}
                  //   defaultChecked={
                  //     (singleProductInfo as any)?.data?.isActive
                  //   }
                />
              </Form.Item>
            </div>
            <div>
              <Divider orientation="left">
                profile picture
                <Tooltip
                  placement="bottom"
                  title={
                    "profile picture should be square. We prefer height 200px and width 200px (Ratio 1)"
                  }
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                imgUrl={profileUrl || ""}
                startUpload={startUpload}
                setStartUpload={(val: any) => {
                  setStartUpload(val);
                }}
                setGeneratedImgUrl={(url: any) => {
                  setProfileUrl(url);
                }}
              />
            </div>
          </div>
          <SubmitResetBtn />
        </div>
      </Form>
    </div>
  );
};

export default AddUser;
