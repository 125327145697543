import { getDeviceInfo } from '../common/device-info'
import axios from 'axios'

export type PaymentMethod = 'COD' | 'SSL'
export type PaymentInput = {
  type?: string
  gateway: PaymentMethod
  orderId: string
  amount: number  
  token: string
  router: any
  removePromo?: () => void
  successUrl?: string
  cancelUrl?: string
  failUrl?: string
}

const PaymentHandler = async ({
  type,
  gateway,
  orderId,
  amount,
  token,
  router,
  removePromo,
  successUrl = '/deposit-order',
  cancelUrl = '/deposit-order',
  failUrl = '/deposit-order',
}: PaymentInput) => {
  // await onCheckout()
  const res = await axios.post(
    `${process.env.REACT_APP_PAY_API}/pay`,
    {
      gateway,
      orderId,
      amount,
      removePromo,
      successUrl: `${window?.location?.origin}${successUrl}`,
      cancelUrl: `${window?.location?.origin}${cancelUrl}`,
      failUrl: `${window?.location?.origin}${failUrl}`,
      type: type || 'JC_COMMERCE',
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...getDeviceInfo(),
      },
    }
  )
  if (res.status === 200) {
    window.location.href=res?.data?.url;
    //router.push(res.data?.url)
    // if (removePromo) removePromo()
    return true
  } else {
    return false
  }
}

export default PaymentHandler