import React from "react";
import MainLayout from "../../components/Layouts";
import RequestTopUpDetails from "../../components/TopUp-History/Details/RequestTopupDetails";

const RequestTopUpDetailsPage = () => {
  return (
    <MainLayout>
      <RequestTopUpDetails />
    </MainLayout>
  );
};

export default RequestTopUpDetailsPage;
