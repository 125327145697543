import MainLayout from "../../components/Layouts";
import CompanyUserAdd from "../../components/Users/AddUser";
import React from "react";

const UserAddPage = () => {
  return (
    <MainLayout>
      <CompanyUserAdd />
    </MainLayout>
  );
};

export default UserAddPage;
