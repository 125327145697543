import MainLayout from "../../components/Layouts";
import CompanyTopUpRequstList from "../../components/TopUp-History/CompanyRequstTopUpList";
import React from "react";

const CompanyTopUpRequestListPage = () => {
  return (
    <MainLayout>
      <CompanyTopUpRequstList />
    </MainLayout>
  );
};

export default CompanyTopUpRequestListPage;
