import { Button, Card, Col, Empty, Form, Input, Row, Select } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";
import styles from "../../styles/tailwind/List.module.css";
// @ts-ignore
import builkcsv from "../../demo-files/topUp_failed_corrected.xlsx";
import * as xlsx from "xlsx";
import clsx from "clsx";

import BreadCrumb from "../Layouts/Breadcrumb";
import { useNavigate } from "react-router";
import { DownloadOutlined } from "@ant-design/icons";
import { instituteArray } from "../../utils";

const drawerWidth = 240;

const companyTopUp = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const CompanyBlance = initialState?.corporateInfo?.balance;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [instituteName, setInstituteName] = useState();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [topUpData, setTopUpData] = useState(new Array());
  console.log("get topup data :", topUpData);
  const [loading, setLoading] = useState(false);
  const [topUpResponseMessage, setTopUpResponseMessage] = useState("");
  const [batchId, setBatchId] = useState("");

  const postTopUp = async () => {
    setLoading(true);
    const topUpRequestsToBeSent = {
      instituteName: instituteName,
      topUpRequests: topUpData,
    };
    const response = await axios
      .post(
        `${process.env.REACT_APP_TOPUP_API}/topup/company/`,
        topUpRequestsToBeSent,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((e) => {
        // console.log('e: ', e)
        console.log("Error: ", e.response.data);

        setTopUpResponseMessage(
          "Top up failed. Server error. Error Code: " +
            e.response.data.status +
            ", error: " +
            e.response.data.error +
            ". Please try again later..."
        );
        setLoading(false);
      });
    console.log("response: ", response);
    if (response && response.status == 200) {
      setLoading(false);
      console.log(response);
      setBatchId(response.data.companyTopUp.id);
      setTopUpResponseMessage(
        "Your topup request for " +
          response.data.companyTopUp.numberOfRequest +
          " numbers of total " +
          response.data.companyTopUp.totalAmount +
          " taka has been successful"
      );
      console.log("topUpResponseMessage:", topUpResponseMessage);
    } else {
      setLoading(false);
      setTopUpResponseMessage(
        "Something went wrong. Please, try again later...."
      );
      console.log("topUp error", response);
    }
  };
  const columns = [
    { field: "name", headerName: "Name", width: 250 },
    { field: "mobileNumber", headerName: "Mobile Number", width: 250 },
    { field: "amount", headerName: "Amount", width: 150 },
    { field: "operator", headerName: "Operator", width: 150 },
    { field: "type", headerName: "Type", width: 150 },
  ];

  // console.log(topUpData)

  const makeLowercaseFirstLetterAndRemoveSpaceFromKeys = (obj: any) =>
    Object.keys(obj).reduce((acc: any, key: any) => {
      acc[(key.charAt(0).toLowerCase() + key.slice(1)).replace(/\s/g, "")] =
        obj[key];
      return acc;
    }, {});

  const readFileAndPostTopUp = (e: any) => {
    e.preventDefault();
    if (e?.target?.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e?.target?.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        let res = new Array(json.length);
        for (let index in json) {
          res[index] = makeLowercaseFirstLetterAndRemoveSpaceFromKeys(
            json[index]
          );
        }

        setTopUpData(res);
        // console.log(json)
        // console.log(res)
        const fileName = (
          document.getElementById("topUpFileUpload") as HTMLInputElement
        )?.value
          ?.split("\\")
          .pop();

        //postTopUp(fileName, res)
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const simpleFileDownload = () => {
    window.location.href = `${builkcsv}`;
  };
  const goToDetail = () => {
    //router.replace(`/topup-history/` + batchId)
    navigate(`/topup-list/${batchId}/details`);
  };
  return (
    <React.Fragment>
      <BreadCrumb title="Topup Request" />
      <div>
        <div className="w-full py-10 h-screen px-2">
          <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden ">
            <div className="w-full row-span-2 md:col-span-4">
              <div className="w-full row-span-2 md:col-span-4">
                <div className="p-4 border-b-2">
                  <span className="text-lg font-bold text-gray-600">
                    Upload your attachments
                  </span>
                </div>

                {!loading && topUpResponseMessage?.length === 0 ? (
                  <div className="p-3">
                    <div className="mb-2">
                      {!isNaN(CompanyBlance) && CompanyBlance < 0 ? (
                        <>
                          <h1>
                            There is no available balance for you.Please update
                            your balance
                          </h1>
                        </>
                      ) : (
                        <>
                          <span>Attachments </span>
                          <form>
                            <Input
                              type="file"
                              className="mb-4"
                              placeholder="Type product name here"
                              id="topUpFileUpload"
                              onChange={readFileAndPostTopUp}
                            />
                          </form>

                          <div className="flex justify-between items-center text-gray-400">
                            <span>Accepted file type: .xlsx files only</span>
                          </div>
                          <div className="d-flex">
                            <Button
                              type="dashed"
                              shape="round"
                              danger
                              onClick={simpleFileDownload}
                            >
                              <DownloadOutlined />
                              Sample File
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <p
                    style={{
                      marginTop: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {topUpResponseMessage}
                  </p>
                )}
              </div>
            </div>
          </div>

          {topUpData.length ? (
            <div
              style={{
                width: "100%",
                marginTop: 15,
                backgroundColor: "rgb(173 173 173 / 12%)",
              }}
            >
              <Form
                name="control-hooks"
                form={form} // like ref
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={postTopUp}
                layout="vertical"
                autoComplete="off"
              >
                <Form.Item
                  label="Institute Name"
                  name="instituteName"
                  rules={[
                    {
                      required: true,
                      message: "Institute Name is Required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Institute Name"
                    onChange={(val) => setInstituteName(val)}
                  >
                    {Object.values(instituteArray)?.map((type, i) => (
                      <Option value={type} key={i}>
                        {type?.split("_").join(" ")}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Mobile Number</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Operator</th>
                      <th scope="col">Type</th>
                    </tr>
                  </thead>

                  <tbody>
                    {topUpData?.length ? (
                      topUpData?.map((topUp: any, index: any) => (
                        <tr className="border-t hover:bg-gray-100" key={index}>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {topUp?.name}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {topUp?.mobileNumber}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {topUp?.amount}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {topUp?.operator}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {topUp?.type}
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div>
                  <Button
                    disabled={loading}
                    loading={(loading ? "loading" : undefined) as any}
                    type="primary"
                    htmlType="submit"
                    className="add-submit-btn text-center mr-2"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
              <div>
                {/* <Button onClick={postTopUp} disabled={loading}>
                  Top Up 
                </Button> */}
              </div>
            </div>
          ) : (
            ""
            // <h3>Please check before you proceed</h3>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default companyTopUp;
