import MainLayout from "../../components/Layouts";
import CompanyDepositForm from "../../components/ComapnyDeposit/PayDepositPayment";
import React from "react";

const DepositCreatePage = () => {
  return (
    <MainLayout>
      <CompanyDepositForm />
    </MainLayout>
  );
};

export default DepositCreatePage;
