import { EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Button, DatePicker, Empty, Form } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";
import moment from "moment";
const { RangePicker } = DatePicker;

const RequestTopUpList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const pageType: any = getParamValue(loc.search, "page-type");
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(20);
  const [showSearch, setShowSearch] = useState(true);
  let now = new Date()
  let Today = now.toISOString();
  let previous30days = new Date(now.setDate(now.getDate() - 30)).toISOString();

  const [range, setRange] = useState<any>({
    from: previous30days,
    to: Today,
  });
  console.log(moment().toISOString(new Date() as any));
  
 
 

  const [requestTopUpData, setRequestTopUpData] = useState<any>({
    loading: false,
    data: null,
  });
  const getTopUpHistory = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setRequestTopUpData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_TOPUP_API}`;
    axios
      .get(
        `${encodedUri}/topup-request/get-all-by-companyId?companyId=${initialState?.companyId}` +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``) +
          (page
            ? `&page=${
                page == requestTopUpData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRequestTopUpData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setRequestTopUpData({ loading: false, data: [] });
        console.error("Banners: Error", err);
      });
  }, [range, limit, page]);

  //
  const reseAllFieldData = () => {
    form.resetFields();
    setRange({
      from: null,
      to: null,
    });
  };
  useEffect(() => {
    getTopUpHistory();
  }, [getTopUpHistory]);

  return (
    <>
      <BreadCrumb
        title="Request TopUp List"
        subTitle={`${
          requestTopUpData?.data?.totalElements ||
          requestTopUpData?.data?.companyTopUps?.length ||
          0
        } Topup(s)`}
        extra={[
          <AddBtn
            onClick={() => {
              navigate("/topup-requst");
            }}
            key={2}
          />,
        ]}
      />
      {showSearch && !pageType && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="range">
              <RangePicker
                // defaultValue={
                //   range.from != null && range.to != null
                //     ? [moment(range.from), moment(range.to)]
                //     : null
                // }
                onChange={(_e, f) => {
                  // console.log("momnet", moment.utc(f[0])?.toISOString());
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {requestTopUpData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Institute Name</th>
                      <th scope="col">Comment</th>
                      <th scope="col">Number Of Request</th>
                      <th scope="col">Total Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {requestTopUpData?.data?.companyTopUps?.length ? (
                      requestTopUpData?.data?.companyTopUps?.map(
                        (topup: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td
                              onClick={() =>
                                navigate(`/request-topup-list/${topup?.id}/details`)
                              }
                            >
                              <span className="font-medium text-gray-500 ml-2">
                                {moment(topup?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.instituteName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.companyComment}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.numberOfRequest}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.totalAmount}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.status?.split("_").join(" ")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...requestTopUpData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default RequestTopUpList;
function UnLoadWindow() {
  throw new Error("Function not implemented.");
}
