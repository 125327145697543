import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Switch,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import PaymentMethod from "./PaymentMethod";
import makePayment, { PaymentInput } from "../common/make-payment";

const DepositList = (): ReactElement => {
  const fetchRef = useRef(0);
  const router = useLocation();
  const navigate = useNavigate();
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const companyId = initialState?.companyId;
  const { type, paymentMethodName, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(20);
  const [selectedMembershipPay, setSelectedMembershipPay] =
  useState<any>(undefined);
  const [visibleModal, setVisibleModal] = useState<any>(false)
  const [orderId,setOrderId] = useState<any>();
  const [payAmount,setPayAmount] = useState<any>();
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedDeposit, setSelectedDeposit] = useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [groupId, setGroupId] = useState<any>();
  const [groupOptions, setGroupOptions] = useState({
    loading: false,
    list: [],
  });
  const [depositData, setDepositData] = useState<any>({
    loading: false,
    data: null,
  });
  const [depositOptions, setDepositOptions] = useState({
    loading: false,
    list: [],
  });
  const getDeposit = useCallback(async () => {
    setDepositData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_PAY_API}`;
    axios
      .get(
        `${encodedUri}/deposit-order?limit=${limit}` +
          (page
            ? `&page=${
                page == depositData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) ,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setDepositData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setDepositData({ loading: false, data: [] });
        console.error("Deposit: Error", err);
      });
  }, [page, limit]);

  const makePaymentHandler = async (err?: Function) => {
    try {
      if (depositData.data) {
        await makePayment({
          gateway: paymentMethodName?.name,
          orderId: orderId,
          amount: payAmount,
          token,
          router,
        } as PaymentInput)
        setVisibleModal(false)
      }
    } catch (e) {
      console.error('paymentSuccess:', e)
      err?.()
    }
  }

  useEffect(() => {
    getDeposit();
  }, [getDeposit]);

  const onClose = () => {
    getDeposit();
    form.resetFields();
    setVisible(false);
    setVisibleModal(false);
    setSelectedMembershipPay(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Deposit List"
        subTitle={`${
          depositData?.data?.totalElements ||
          depositData?.data?.depositOrders?.length ||
          0
        } Deposit(s)`}
        extra={[
          <AddBtn
            onClick={() => {
              navigate("/deposit-order-create");
            }}
            key={2}
          />,
        ]}
      />

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {depositData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Amount</th>
                      <th scope="col">Due Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col">Comment</th>
                      <th scope="col">Reference</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {depositData?.data?.depositOrders?.length ? (
                      depositData?.data?.depositOrders?.map(
                        (deposit: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedDeposit(deposit)}
                          >
                            <td>
                            {deposit?.orderId}
                            </td>
                            <td>
                              <div className="checkbox-large flex items-center gap-2">
                                <span className="font-medium text-gray-500 ml-2">
                                  {deposit?.amount}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="checkbox-large flex items-center gap-2">
                                <span className="font-medium text-gray-500 ml-2">
                                  {deposit?.dueAmount}
                                </span>
                              </div>
                            </td>
                            <td>{deposit?.dueAmount}</td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {deposit?.status}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {deposit?.reference}
                              </span>
                            </td>
                            
                            <td>
                              <div className="flex gap-2">
                              {deposit?.paymentStatus !== 'PAID' ? (
                 
                 <Button
                     type="primary"
                     onClick={(e) => {
                       e.preventDefault();
                       e.stopPropagation();
                       setSelectedMembershipPay(deposit);
                       setOrderId(deposit?.orderId);
                       setPayAmount(deposit?.dueAmount);
                     }}
                     className="mb-4"
                     style={{background: '#3a494e'}}
                   >
                     Pay
                   </Button>
               ):(
                <span className="font-bold text-lg text-green-600">{deposit?.paymentStatus}</span>
               ) }
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <Pagination
          {...depositData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />

      </div>
      <Modal
        title="Payment Method"
        onCancel={onClose}
        open={selectedMembershipPay}
        width={700}
        centered
        footer={false}
      >
        <PaymentMethod
          onCloseMethod={onClose}
          visibleData={selectedMembershipPay}
          onModalSubmit={makePaymentHandler}
        />
      </Modal>
    </>
  );
};

export default DepositList;
