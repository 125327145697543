import axios from "axios";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Tooltip,
  TooltipProps,
} from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { getPage, getParamValue, TopUpStatusTypeArray } from "../../../utils";
import { useLocation, useNavigate, useParams } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import React from "react";
import { Loader } from "../../common";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";
import { responseNotification } from "../../../utils/notify";

const RequestTopUpHistoryList = ({ refetch }: any): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const loc = useLocation();
  const route = useParams();
  const companyTopUpId = (route as any).topupId;
  const [form] = Form.useForm();
  const [statusModal, setStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [note, setNote] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [topUpHistoryData, setTopUpHistoryData] = useState<any>({
    loading: false,
    data: null,
  });
  console.log("top Up History Data 2::", topUpHistoryData);
  const [arrow, setArrow] = useState<"Show" | "Hide" | "Center">("Show");

  const mergedArrow = useMemo<TooltipProps["arrow"]>(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const getTopUpHistory = useCallback(async () => {
    setTopUpHistoryData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_TOPUP_API}`;
    axios
      .get(
        `${encodedUri}/topup-request/get-topup-list-by-company-topup-id?companyId=${initialState?.companyId}` +
          (companyTopUpId ? `&companyTopUpId=${companyTopUpId}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTopUpHistoryData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTopUpHistoryData({ loading: false, data: [] });
        console.error("Banners: Error", err);
      });
  }, [companyTopUpId]);

  const onStatusChange = async (data: any) => {
    if (status === "REJECTED" && !note) {
      responseNotification("To rejected note is required!");
    } else {
      setLoading(true);

      const readyData = data && {
        companyTopUpId: companyTopUpId,
        status: status,
        comment: note,
      };

      await fetch(
        `${process.env.REACT_APP_TOPUP_API}/topup-request/company/approve`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Approved Status Updated Successfully",
              "success"
            );
            form.resetFields();
            setStatusModal(false);
            navigate(-1);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  //

  useEffect(() => {
    getTopUpHistory();
  }, [getTopUpHistory]);

  return (
    <>
      <BreadCrumb
        title="TopUp History"
        subTitle={`${
          topUpHistoryData?.data?.totalElements ||
          topUpHistoryData?.data?.topUps?.length ||
          0
        } Topup(s)`}
        extra={<></>}
      />

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {topUpHistoryData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">SL</th>
                      <th scope="col">Name</th>
                      <th scope="col">Mobile Number</th>
                      <th scope="col">designation</th>
                      <th scope="col">Operator</th>
                      <th scope="col">Type</th>
                      <th scope="col">TopUpId</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col">Date</th>
                      <th scope="col">Created Response</th>
                      <th scope="col">Failed Reason</th>
                    </tr>
                  </thead>

                  <tbody>
                    {topUpHistoryData?.data?.topUpResponses?.length ? (
                      topUpHistoryData?.data?.topUpResponses?.map(
                        (topup: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {index + 1}
                              </span>
                            </td>

                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.mobileNumber?.split("+88")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.designation}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.operator}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.type}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.topUpId}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.amount}
                              </span>
                            </td>
                            <td
                              style={
                                topup?.status == "Success" &&
                                topup?.rechargeStatus == "900"
                                  ? { color: "green" }
                                  : { color: "red" }
                              }
                            >
                              {topup?.status}
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {moment(topup?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <Tooltip
                                placement="top"
                                title={topup?.createdResponse}
                                arrow={mergedArrow}
                              >
                                <Button>Response</Button>
                              </Tooltip>
                            </td>
                            <td>
                              <Tooltip
                                placement="top"
                                title={topup?.failedReason}
                                arrow={mergedArrow}
                              >
                                <Button>Reason</Button>
                              </Tooltip>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="pt-10">
        <Button
          type="dashed"
          onClick={() => setStatusModal(true)}
          title="Change Status"
        >
          Approved <FontAwesomeIcon icon={faSortAmountDownAlt} />
        </Button>
      </div> */}

      <Modal
        title={"Change Topup Approve Status"}
        okButtonProps={{ disabled: !!!status }}
        open={!!statusModal}
        onOk={onStatusChange}
        okText="Change"
        cancelButtonProps={{
          color: "red",
          type: "ghost",
          danger: true,
        }}
        confirmLoading={loading}
        onCancel={() => {
          setStatusModal(false);
        }}
        destroyOnClose={true}
      >
        <div>
          <Form layout="vertical" onFinish={onStatusChange}>
            <Form.Item
              hasFeedback
              label="Status"
              name="status"
              initialValue={status}
              rules={[
                {
                  required: true,
                  message: "Status is required",
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                onChange={(val) => setStatus(val as string)}
                filterOption={() => {
                  return true;
                }}
              >
                {Object.values(TopUpStatusTypeArray)?.map((type, i) => (
                  <Option value={type} key={i}>
                    {type?.split("_").join(" ")}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item hasFeedback label="Note" name="note">
              <Input.TextArea
                onChange={(val) => setNote(val?.target.value)}
                rows={5}
                id="note"
                placeholder="Write some note"
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default RequestTopUpHistoryList;
function UnLoadWindow() {
  throw new Error("Function not implemented.");
}
