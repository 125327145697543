import {
    Avatar,
    Button,
    Card,
    Divider,
    Form,
    Input,
    Radio,
    Space,
    Switch,
  } from "antd";
  import { useCallback, useEffect, useState } from "react";
  import { responseNotification } from "../../utils/notify";
  import SubmitResetBtn from "../common/SubmitBtn";
  import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
  import { useDispatch, useSelector } from "react-redux";
  import s from "./PaymentMethodView.module.css";
  import React from "react";
  import axios from "axios";
  import cn from "classnames";
  import { Loader } from "../common";
  import { getImgUrl } from "../../utils";
  import { SET_PAYMENT_METHOD_INFO } from "../../redux/auth/authType";
  interface PaymentMethodViewProps {
      view: 'cart' | 'sidebar' | 'modal' | 'prePayment'
      onModalSubmit?: Function
      headingSize?: 'sm'
      setFinalPayment?: any
      finalPayment?: number
      total?: number
      minPayableAmount?: number
    }
  
  const PaymentMethod = ({ onModalSubmit, view, total = 10, minPayableAmount = 10, visibleData, onCloseMethod }: any) => {
    const { token} = useSelector((state) => (state as any)?.authReducer);
    const type = "JC_COMMERCE";
    const [finalPayment,setFinalPayment] = useState(false);
    const [partialPayment, setPartialPayment] = useState(false);
    const dispatch = useDispatch();
    const [paymentMethod,setPaymentMethod] = useState<any>();
    const [paymentMethods, setPaymentMethods] = useState({
      loading: true,
      list: [],
    }); 
    const pathname = window.location.pathname;
    const [error, setError] = useState("");
    const [form] = Form.useForm();
    const [updateEveryWhere, setUpdateEveryWhere] = useState(false);
    const [loading, setLoading] = useState(false);
    const [productData, setProductData] = useState<any>(undefined);
    const [variationImageUrl, setVariationImageUrl] = useState<string[]>([]);
  
    const fetchPaymentMethods = useCallback(async() => {
        try {
          setPaymentMethods({ loading: true, list: [] });
          axios
            .get(`${process.env.REACT_APP_PAY_API}/payment-method?type=${type}`, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            })
            .then((data) => {
              if (data.status === 200) {
                setPaymentMethods({
                  loading: false,
                  list: data?.data?.methods,
                });
                if (data?.data?.methods?.length) {
                  data?.data?.methods?.forEach((method: any) => {
                if (method.name === 'COD') {
                  setPaymentMethod(method.name)
                }
              })
              if (!paymentMethod) {
                setPaymentMethod(data?.data?.methods[1].name)
              }
            } else {
              setPaymentMethod(null)
            }
              } else {
                setPaymentMethods({ loading: false, list: [] });
                responseNotification(
                  data.statusText || "something went wrong",
                  "error"
                );
              }
            })
            .catch((err) => {
              setPaymentMethods({ loading: false, list: [] });
            });
        } catch (error) {
          setPaymentMethods({ loading: false, list: [] });
        }
      
    }, [token]);
  
  
    useEffect(() => {
      fetchPaymentMethods();
    }, [fetchPaymentMethods, type]);
  
    useEffect(() => {
      if (visibleData) {
        setProductData(visibleData);
      }
    }, [visibleData]);
  
    useEffect(() => {
      if (productData) {
        form.resetFields();
      }
    }, [productData, form, visibleData]);
  
    const methods = [...paymentMethods?.list];
    methods?.sort((a: any, b: any) => (a.name.length < b.name.length ? -1 : 1));
    return (
      <>
        
          {/* payment method */}
          <div
            className="flex-1"
          >
            <span className="font-semibold text-lg block">Payment Method</span>
  
            <div
              className={s.fieldset}
            >
              <label className={s.label}>Available Payments 22</label>
            </div>
  
            <div>
              {paymentMethods?.loading ? (
                <Loader size="sm" />
              ) : (
                  methods?.map((item: any) => {
                  return (
                    (item?.name === "COD" && view === "prePayment") ||
                    (item?.name === "COD" && view === "modal") || (
                      <div className="flex flex-nowrap items-center mb-3 justify-between min-w-full">
                        <Radio
                          name="payment"
                          value={item?.name}
                          checked={item?.name === paymentMethod}
                          onChange={(e) => {
                            if (e.target.checked) setPaymentMethod(item?.name);
                            if (e.target.checked) {
                              dispatch({
                                type: SET_PAYMENT_METHOD_INFO,
                                payload: {
                                  paymentMethodName: {
                                    name: item?.name,
                                  },
                                },
                              });
                            }
                          }}
                          className="w-full" 
                        > 
                          <div className="flex gap-2 items-center justify-between w-full">
                            <span className="ml-1 whitespace-nowrap">
                              {item?.name}
                            </span>
                            <div className="text-xs text-accent text-right">
                              {item?.description}
                            </div>
                          </div>
                        </Radio>
                        {item?.logo && (
                          <img
                            src={getImgUrl(item?.logo)}
                            height="20"
                            alt={item?.name}
                            style={{ height: 20 }}
                          />
                        )}
                      </div>
                    )
                  );
                })
              )}
  
              {paymentMethod !== "COD" && (
                <>
                  <div className="flex items-center bg-accent-1 p-2 rounded-md mb-4">
                    <Radio.Group
                      onChange={(e: any) => setPartialPayment(e.target.value)}
                      value={partialPayment}
                    >
                      <Radio name="paymentType" value={false}>
                        Full
                      </Radio>
                      <Radio name="paymentType" value={true}>
                        Partial
                      </Radio>
                    </Radio.Group>
                    {partialPayment && (
                      
                      <Input
                        type="number"
                        min={1}
                        onChange={(value: any) => {
                          const val:any = value ? parseFloat(value) : 0;
                          if (val > total) {
                          } else if (val < minPayableAmount) {
                            setFinalPayment(minPayableAmount);
                          } else setFinalPayment(val);
                        }}
                        defaultValue={total}
                      />
                    )}
                  </div>
  
                  {partialPayment && minPayableAmount > 0 && (
                    <span className={cn("py-2 text-sm text-accent-12")}>
                      Minimun payable amount {minPayableAmount}
                    </span>
                  )}
                </>
              )}
              <div id="msg-pay-amount" className="text-sm py-2" />
            </div>
          </div>
          {/* payment method end */}
            <div className="sticky z-20 bottom-0 right-0 left-0 py-6 mt-12 bg-accent-0 border-t border-accent-2 mx-6">
              <Button
                type="primary"
                className="mb-1 w-full"
                onClick={() => {
                  setLoading(true)
                  onModalSubmit?.((e: { message: any }) => {
                    setLoading(false)
               
                  })
                }}
                loading={loading}
                disabled={paymentMethod === 'COD'}
              >
                Confirm
              </Button>
              <small id="msg" />
            </div>
       
      </>
    );
  };
  
  export default PaymentMethod;