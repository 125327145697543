import MainLayout from "../../components/Layouts";
import CompanyDepositList from "../../components/ComapnyDeposit/index";
import React from "react";

const DepositPage = () => {
  return (
    <MainLayout>
      <CompanyDepositList />
    </MainLayout>
  );
};

export default DepositPage;
