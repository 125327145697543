import { Button, Form, Input, Modal, Select } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import React from "react";
import axios from "axios";
import { debounce } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router";
import { mobileOperatorType, simOperatorType } from "../../utils";
import { SubmitResetBtn } from "../common";
import PaymentMethod from "./PaymentMethod";
import makePayment, { PaymentInput } from '../common/make-payment';

const CompanyDeposit = ({ visibleData, onCloseMethod }: any) => {
  const fetchRef = useRef(0);
  const route = useParams();
  const router = useLocation();
  const navitage = useNavigate();
  const { token,paymentMethodName } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const companyId = initialState?.companyId;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [payAmount,setPayAmount] = useState<any>();
  const [orderId,setOrderId] = useState<any>();
  const [operatorType, setOperatorType] = useState();
  const [mobileOperator, setMobileOperator] = useState();
  const [employeeData, setEmployeeData] = useState<any>({
    loading: false,
    data: null,
  });

  const [selectedMembershipPay, setSelectedMembershipPay] =
    useState<any>(undefined);
  const [visibleModal, setVisibleModal] = useState<any>(false)

  const [groupId, setGroupId] = useState<any>();
  const [designationId, setDesignationId] = useState<any>();
  const [departmentId, setDepartmentId] = useState<any>();
  const [groupOptions, setGroupOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [designation, setDesignation] = useState<any>({
    list: [],
    loading: false,
  });
  const [departmentOptions, setDepartmentOptions] = useState({
    loading: false,
    list: [],
  });
 
  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      amount: data.amount,
      comment: data.comment,
      reference: data.reference,
      depositTo: 'COMPANY',
    };
      await fetch(`${process.env.REACT_APP_PAY_API}/deposit-order`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
console.log('get form data :::',res);
          if (res.statusCode === 200) {
            setSelectedMembershipPay(res);
            setOrderId(res?.depositOrder?.orderId);
            setPayAmount(res?.depositOrder?.amount);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    
  };

  const makePaymentHandler = async (err?: Function) => {
    try {
        await makePayment({
          gateway: paymentMethodName?.name,
          orderId: orderId,
          amount: payAmount,
          type: "JC_DEPOSIT",
          token,
          router,
        } as PaymentInput)
        setVisibleModal(false)
      
    } catch (e) {
      console.error('paymentSuccess:', e)
      err?.()
    }
  }


  useEffect(() => {
    if (visibleData) {
      setEmployeeData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (employeeData?.data) {
      form?.resetFields();
    }
  }, [employeeData]);
  const onClose = () => {
    form.resetFields();
    setSelectedMembershipPay(undefined);
    setVisibleModal(false);
  };

  return (
    <div className="mt-6">
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...employeeData?.data,
        }}
      >
        <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
          <div className="grid grid-cols-2 gap-x-6">
            <div>
              <Form.Item
                hasFeedback
                label="Deposit Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Amount is Required!",
                  },
                ]}
                initialValue={"0"}
              >
                <Input
                  id="amount"
                  type="number"
                  placeholder="Enter deposit amount"
                  min={0}
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="Comment"
                rules={[
                  {
                    required: false,
                    message: "Comment is Required!",
                  },
                ]}
                name="comment"
                // initialValue={employeeData?.data?.name}
              >
                <Input
                  id="comment"
                  type="text"
                  placeholder="Enter your comment"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Reference"
                rules={[
                  {
                    required: false,
                    message: "reference",
                  },
                ]}
                name="reference"
              >
                <Input
                  id="reference"
                  type="text"
                  placeholder="Enter reference"
                />
              </Form.Item>
            </div>
          </div>
          <SubmitResetBtn />
        </div>
      </Form>


      <Modal
        title="Payment Method"
        onCancel={onClose}
        open={selectedMembershipPay}
        width={700}
        centered
        footer={false}
      >
        <PaymentMethod
          onCloseMethod={onClose}
          visibleData={selectedMembershipPay}
          onModalSubmit={makePaymentHandler}
        />
      </Modal>
      
    </div>
  );
};

export default CompanyDeposit;
